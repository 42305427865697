import { EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { ProposalEnquiryEntity, EMPTY_PROPOSAL_ENQUIRY_ENTITY } from "domain/entity/ProposalEnquiry/ProposalEnquiryEntity";
import { ProposalEnquiryRepository } from "domain/repository/Proposal/ProposalEnquiryRepo";
import _ from "lodash";
import { ProposalEnquiryDetailModel } from "presentation/model/ProposalEnquiry/ProposalEnquiryDetailModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface ProposalEnquiryDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ProposalEnquiryDetailModel>> | ((value: SetStateAction<ProposalEnquiryDetailModel>) => void),
    ]    
    proposalEnquiryRepo: ProposalEnquiryRepository,
}

export const ProposalEnquiryDetailVM = ({ dispatch, proposalEnquiryRepo }: ProposalEnquiryDetailVMProps) => {
    const [proposaDtlDispatch] = dispatch;

    const onPageInit = (currentProposalEnquiry: ProposalEnquiryEntity | null) => {
        let proposal: ProposalEnquiryEntity ;
        

        // let publishAtWebsiteInd;
        // let issueByEdiInd;
        // const docIssue = currentProposal?.docIssue;

        // if (docIssue?.issueType) {  
        //     publishAtWebsiteInd = docIssue.issueType.split(';').includes(DocumentConst.issueType.ISSUE_TYPE_E_INVOICE) ? 'Y' : 'N';  
        //     issueByEdiInd = docIssue.issueType.split(';').includes(DocumentConst.issueType.ISSUE_TYPE_EDI) ? 'Y' : 'N';  
        // }

        proposal = {
            ...currentProposalEnquiry,
            // publishAtWebsiteInd: currentDocHdr?.publishAtWebsiteInd??publishAtWebsiteInd,
            // issueByEdiInd: currentDocHdr?.issueByEdiInd??issueByEdiInd,
        }
        let tierList = proposal?.tierList??[];

        proposaDtlDispatch(prevState => {
            return {
                ...prevState,
                currentProposalEnquiry: proposal,
                tariffItemTiers: tierList,
                detailState: {
                    ...prevState.detailState,
                    isAdd: true,
                }
            }
        })
    }

    const onShowLoading = () => {
        proposaDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        proposaDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const onCloseClick = () => {
        proposaDtlDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail:true,
                isAllowAutoSearch:true,
                // selectedRows:[],
                // currentSelectedRow: {...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY},
            }
        }); 
    }

    const onFetchProposal = async (proposal: ProposalEnquiryEntity) =>{
        
        proposaDtlDispatch(prevState => {
            return {
                ...prevState,
                currentProposalEnquiry: proposal,
            }
        })
    }


    const onFetchTierList = async (tierList: StdProposalTariffItemTierEntity[]) =>{
        proposaDtlDispatch(prevState => {
            return {
                ...prevState,
                tariffItemTiers: tierList,
            }
        })
    }

    const onSearchTierList = async (proposalItemId: number) =>{
        
        await proposalEnquiryRepo.searchTierListById(proposalItemId).then((data) => {
            return proposaDtlDispatch(prevState => {
                return {
                        ...prevState,
                        tariffItemTiers: data,
                    }          
                })
        })
    }

    return {
        onPageInit,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onCloseClick: onCloseClick,
        // onSearch: onSearch,
        onFetchProposal: onFetchProposal,
        onFetchTierList: onFetchTierList,
        onSearchTierList: onSearchTierList,
    }
}