
import { INITIAL_PROPOSAL_ENQUIRY_DETAIL_COL_DEF, transferRowData } from "presentation/constant/ProposalEnquiry/ProposalEnquiryDetailColumnDefinition";
import { useProposalEnquiryDetailTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryDetailProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";

const ProposalEnquiryDetailTablePanel:React.FC = () => {    
    const [ proposalEnqDtlState ] = useProposalEnquiryDetailTracked();
    let gridRef: any = useRef();
    
    const memoProposalEnqDtlTable = useMemo(() => {
        return <HPHTable
            id='proposal-enquiry-detail-table'
            columns={INITIAL_PROPOSAL_ENQUIRY_DETAIL_COL_DEF.slice()}
            data={transferRowData(proposalEnqDtlState.tariffItemTiers??[]) }
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef} />;
    }, [proposalEnqDtlState.tariffItemTiers])

    return <>{ <TableWrapper>{memoProposalEnqDtlTable}</TableWrapper>}</>;
}

export default memo(ProposalEnquiryDetailTablePanel);