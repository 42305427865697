import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { ProposalEnquiryEntity } from "domain/entity/ProposalEnquiry/ProposalEnquiryEntity";
// import { StandardProposalEnabledSearchCriteria, DEFAULT_ENABLED_STANDARD_PROPOSAL_SEARCH_CRITERIA } from "presentation/constant/StandardProposal/StandardProposalEnabledSearchCriteria";
import { ProposalEnquirySearchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
// import { GeneralProposalSearchCriteria, EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import { RefObject } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";
import { DropdownProps } from "../DropdownProps";

export interface ProposalEnquiryDropdownOptions {
    //To add: Effective Date Range, Proposal No
        companyCodeDropdownOptions: DropdownProps[],
        operatingTmlDropdownOptions: DropdownProps[],
        tariffTypeDropdownOptions: DropdownProps[],
        tariffCodeDropdownOptions: {[key:string]: DropdownProps[]},
        proposalStatusDropdownOptions: DropdownProps[],
        activeIndDropdownOption: DropdownProps[],
    }
    
    // export interface GroupCheckboxs {
    //     billingCycleCheckBoxOptions: GroupCheckboxList[],
    // }
    
    
    
    export interface ProposalEnquiryModel {
        isLoading:boolean,
        isShowRightCriteriaPanel: boolean,
        isShowProposalDetail: boolean,
        isFilterSearchCriteria: boolean,
        isEditSearchCriteria: boolean,
        checkboxRef: { [key: string]: HTMLElement | null },
        // enabledSearchCriteria: StandardProposalEnabledSearchCriteria,
        // checkboxValue: StandardProposalEnabledSearchCriteria,
        searchCriteria: ProposalEnquirySearchCriteria,
        highlightedCheckboxKey?: string | null
        currentTableRef?: RefObject<any>,
        searchCounter: number,
        dynamicOptions: ProposalEnquiryDropdownOptions,
        documentActive: boolean,
        // isShowSaveNewConfirmModal: boolean,
        // isShowDeleteConfirmModal: boolean,
        userEmail: string,
        // searchPreferences: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria>[],
        // currentSearchPreference: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria> | null,
        // openedContainerNo?: string,
        // isShowContainerDetail: boolean;
        proposals: ProposalEnquiryEntity[],
        currentProposal: ProposalEnquiryEntity | null,
        isShowLegendButton: boolean,
        selectedRows: ProposalEnquiryEntity[],
        // isShowPrintPad: boolean,
        // docPrintCriteria: DocumentEnquiryPrintCriteria,
        // dynamicGroupCheckboxs: GroupCheckboxs,
        // currentHdr: DocumentHeaderEntity | null,
    }
    
    export const EMPTY_PROPOSAL_ENQUIRY_MODEL:  ProposalEnquiryModel = {
        isLoading: true,
        isShowRightCriteriaPanel: true,
        isShowProposalDetail: false,
        isFilterSearchCriteria: true,
        isEditSearchCriteria: true,
        checkboxRef: {},
        // enabledSearchCriteria: StandardProposalEnabledSearchCriteria,
        // checkboxValue: StandardProposalEnabledSearchCriteria,
        searchCriteria: {...EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA} ,
        highlightedCheckboxKey: null,
        searchCounter: 0,
        dynamicOptions: {
            companyCodeDropdownOptions: [],
            operatingTmlDropdownOptions: [],
            tariffTypeDropdownOptions: [],
            tariffCodeDropdownOptions: {},
            proposalStatusDropdownOptions: [],
            activeIndDropdownOption: [],
        },
        documentActive: false,
        // isShowSaveNewConfirmModal: boolean,
        // isShowDeleteConfirmModal: boolean,
        userEmail: "",
        // searchPreferences: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria>[],
        // currentSearchPreference: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria> | null,
        // openedContainerNo?: string,
        // isShowContainerDetail: boolean;
        proposals: [],
        currentProposal: null,
        isShowLegendButton: false,
        selectedRows: [],
        // isShowPrintPad: boolean,
        // docPrintCriteria: DocumentEnquiryPrintCriteria,
        // dynamicGroupCheckboxs: GroupCheckboxs,
        // currentHdr: DocumentHeaderEntity | null,
    }
    