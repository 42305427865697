import { StdProposalIsEntity } from "../StandardProposal/StdProposalIsEntity";
import { StdProposalTariffItemTierEntity } from "../StandardProposal/StdProposalTariffItemTierEntity";

export interface ProposalEnquiryEntity {
	
    proposalId?: number | null,
	proposalItemId?: number | null,
    companyCode?: string | null,	
	proposalNo?: string | null,	
	operatingTml?: string | null,
	effectiveDate?: Date | null,	
    // effectiveFromDate?: Date | null,	
    // effectiveToDate?: Date | null,	
	seq?: number | null,	
	tariffType?: string | null,	
	tariffCode?: string | null,	
	currencyCode?: string | null,	
	forwarderCode?: string | null,
	proposalType?: string | null,
	proposalStatus?: string | null,		
	activeInd?: string | null,	
	remarks?: string | null,	

    tierList?: StdProposalTariffItemTierEntity[]  | null,
    stdIsList?: StdProposalIsEntity[]  | null,

    [key: string]: string | number | Date | null | undefined | Object
}

export const EMPTY_PROPOSAL_ENQUIRY_ENTITY: ProposalEnquiryEntity = {

    proposalId: null,
	proposalItemId: null,
    companyCode: '',	
	proposalNo: null,	
	operatingTml: '',
	effectiveDate: null,	
	seq: null,	
	tariffType: '',	
	tariffCode: '',	
	currencyCode: '',	
	forwarderCode: '',	
	proposalType: '',	
	proposalStatus: 'NEW',	
	activeInd: '',	
	remarks: '',	

    tierList: [],
    stdIsList: [],

}